.app {
  color: #ffffff;
  text-align: center;
  font-family: CenturyGothic;
  font-size: calc(16px + 0.35vw);
  font-weight: 800;
}

.theOnionRing {
  margin: 25px 0;
  width: 25%;
}

.title {
  margin: auto;
  width: 50%;
}

.subtitle {
  margin: auto;
  width: 35%;
}

.shareContainer {
  padding-top: 140px;
}

.inputBox {
  background-color: #000000;
  border: 3px solid #ffffff;
  color: #ffffff;
  font-family: CenturyGothic;
  font-size: calc(16px + 0.35vw);
  margin-top: 10px;
  margin-right: 15px;
  padding: 15px 5px;
}

.inputBox:hover {
  background-color: #ffffff;
  color: #000000;
}

.phoneBox {
  text-align: center;
  width: 280px;
}

.submitButton {
  cursor: pointer;
  width: 200px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footerLogo {
  margin: 90px 0 0px 0;
}

.nextVictimBtn {
  margin-bottom: 30px;
}

.footer {
  font-size: 10px;
  margin-bottom: 10px;
}

.presentedBy {
  width: 200px;
}

.AWLogo {
  width: 100px;
}

.btn {
  background-color: #000000;
  border: 3px solid #ffffff;
  color: #ffffff;
  font-family: CenturyGothic;
  font-size: calc(16px + 0.35vw);
  padding: 10px 20px;
  cursor: pointer;
}

.btn:hover {
  background-color: #ffffff;
  color: #000000;
}

.textarea {
  resize: none;
  margin-left: 5px;
}

.step {
  margin: 50px auto;
}

.copyBtn {
  width: 200px;
  margin-left: 20px;
  margin-right: 5px;
}

.videoText {
  margin: 60px auto;
}

*:focus {
    outline: none;
}

.privacyLink {
    color: #FFFFFF;
}

.videoWrapper {
  border: 3px solid #ffffff;
  margin:auto;
  width: 50%;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.answerItWrapper {
  position: absolute;
  top: 40%;
  left: 0;
  font-size: calc(26px + 0.35vw);
  width:100%
}

@media screen and (max-width: 992px) {
  .theOnionRing {
    width: 30%;
  }
  .title {
    width: 80%;
  }
  .subtitle {
    width: 50%;
  }
  .videoWrapper {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .theOnionRing {
    width: 40%;
  }
  .title {
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
  }
  .subtitle {
    width: 60%;
  }
  .videoWrapper {
    margin:0;
    width: auto;
  }
  .shareContainer {
    padding-top: 30px;
  }
  .step {
    margin: 30px auto;
  }
  .paddingBottom50 {
    padding-bottom: 20%;
  }
  .nextVictimBtn {
    margin-bottom: 20px;
  }
  .footerLogo {
    margin: 30px 0 0px 0;
  }
  .footer {
    margin-bottom: 0;
  }
}

@font-face {
  font-family: 'CenturyGothic';
  font-size: calc(16px + 0.35vw);
  src: url(./fonts/CenturyGothic-SimplePainted-Regular.otf);
}

body, html {
  background: url('./assets/VHS_Background.jpg') no-repeat center fixed;     
  background-size: cover;
  height: 100%;
  margin: 0;
  font-family: CenturyGothic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
